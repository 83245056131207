import React from 'react';
import './App.scss';
import { BrowserRouter as Router } from 'react-router-dom';
import { ContextProvider } from './context/Context';
import SwitchPage from './pages/switchPage';

const App = () => {
  return (
    <ContextProvider>
      <Router>
        <SwitchPage />
      </Router>
    </ContextProvider>
  );
};

export default App;
