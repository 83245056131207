import React, { Suspense } from 'react';
import { Switch, Route } from 'react-router-dom';
import Loading from '../../components/loading';

const InitColumn = React.lazy(() => import('../initColumn'));
const Confirmation = React.lazy(() => import('../confirmation'));
const presentation = React.lazy(() => import('../presentation'));

const SwitchPage = () => {
  return (
    <Suspense fallback={<Loading />}>
      <Switch>
        <Route exact path="/confirmation" component={Confirmation} />
        <Route exact path="/" component={InitColumn} />
        <Route exact path="/presentation" component={presentation} />
      </Switch>
    </Suspense>
  );
};
export default SwitchPage;
