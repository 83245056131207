import React from 'react';
import loader from '../../assets/img/loader.svg';
import './index.scss';

export default function Loading() {
  return (
    <div className="content-loader">
      <img src={loader} alt="loading" />
    </div>
  );
}
