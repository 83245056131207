import React, { useState } from 'react';

export const Context = React.createContext();

export const ContextProvider = ({ children }) => {
  const [exampleContext, seteExampleContext] = useState([]);

  const appState = {
    exampleContext,
    seteExampleContext,
  };

  return (
    <Context.Provider value={appState}>
      { children }
    </Context.Provider>
  );
};

export const ContextConsumer = Context.Consumer;
